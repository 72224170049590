import {useContext, useEffect,useState} from 'react'
import style from './mainPage.module.css'
import ProfilBox from '../../components/profilBox/ProfilBox'
import Button from '../../components/button/Button'
import QuestionnaireBox from '../../components/questionnaireBox/QuestionnaireBox'
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../components/auth/AuthContext'
import { postData } from '../../components/API/function'
import { CustomResponse } from '../../components/type/Response'
import { Questionnaire } from '../../components/type/Questionnaire'
import { QuestionnaireAggregationResult } from '../../components/type/Questionnaire'



function MainPage() {

  const navigate : NavigateFunction = useNavigate();
  const {user}  = useContext(AuthContext);
  const [questionnaire,setQuestionnaire] = useState<Questionnaire[]>([])
  const [questionCountResults, setQuestionCountResults] = useState<QuestionnaireAggregationResult[]>([]);

  async function getQuestionnaire() : Promise<void>{
    await postData<CustomResponse | Questionnaire[]>('/questionnaires').then((resp)=>{
      if (!(resp as CustomResponse).message ) {
        setQuestionnaire(resp as Questionnaire[])
      }else{
        console.log((resp as CustomResponse).message)
      }
    })
  }

  async function getnbquestion(): Promise<void> {
    await postData<QuestionnaireAggregationResult[]>('/question-count').then((resp) => {
      if (Array.isArray(resp)) {
        setQuestionCountResults(resp);
      } else {
        console.log('La réponse n\'est pas un tableau d\'objets attendu.');
      }
    });
  }

  useEffect(()=>{
    getQuestionnaire()
    getnbquestion()
  },[])

  // Fonction pour créer un questionnaire vers la page "questionnaireEdit"
  const goToQPage = () : void => {
    navigate('/questionnaire',{ state: {name_questionnaire:"Questionnaire n°"+(questionnaire.length+1)}});
  };
 

  return (
    <div className={style.background}>
        <div className={style.container}>
            <ProfilBox username={user?.login} email={user?.mail} nbrQuestionnaire={questionnaire.length} date="12/09/2023"></ProfilBox>

            <div className={style.box}>
                <div className={style.header}>
                Questionnaire
                </div>
                <div className={style.underline}></div>
                <div className={style.questionnaireSection}>
                    <div className={style.questionnaireList}>
                        {questionnaire.map((item)=>{
                            const result = questionCountResults.find((res) => res._id === item._id);
                            const nb_question_ = result ? result.questionCount : 0; // Définissez la valeur par défaut selon vos besoins
                            return (
                
                            <QuestionnaireBox questionnaireTitre={item.name} nb_question={nb_question_} key={item._id} id_questionnaire={item._id} reload={getQuestionnaire} ></QuestionnaireBox>
                          )
                        })}
                      
                    </div>                    
                    <Button texte='Créer un nouveau questionnaire' className={style.addBtn} onclick={goToQPage}></Button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MainPage