import { useState ,useEffect} from 'react'
import style from './Waitting.module.css'
import PlayerContainer from '../../components/playerContainer/PlayerContainer'
import { socket } from '../../components/API/socket';
import { Player } from '../../components/type/Player';
import { useParams } from 'react-router-dom';
import Input from '../../components/input/Input';
import Button from '../../components/button/Button';
import { Store } from 'react-notifications-component';


function Waitting() {

 const {gameCode} = useParams();

  const [players, setPlayers] = useState<Player[]>([]);
  const [questionnaire, setQuestionnaire] = useState<string>()
  const [isPseudoSet,setIsPseudoSet] = useState<boolean>(false);
  const[pseudo,setPseudo] = useState<string>("");

  useEffect(() => {

    if(!gameCode){
        console.log("Game code inconnu");
        return;
    }
    console.log("Game code :",gameCode);

    socket.on('player_join',(data)=>{
        setPlayers(data.players);
        setQuestionnaire(data.questionnaire);
    })
  },[])

  function handlePseudoChange(value:string){
    setPseudo(value);
  }

  function changePseudo(){

    if(pseudo && !isPseudoSet && gameCode){
        socket.emit("player_join",{gameCode: gameCode,pseudo })
        setIsPseudoSet(true);
    }else if (!pseudo){
      Store.addNotification({
        title: "Pseudo vide",
        message: "Le pseudo ne peut pas être vide.",
        type: "danger",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      }); 

    }
  }

  return (
    <div className={style.background}>
        <div className={style.titlebox}>
            {questionnaire && <p>Questionnaire : {questionnaire}</p> }
        </div>
        <div className={style.center}>
            <p className={style.code}>Code : {gameCode}</p>
            { !isPseudoSet ? ( <>
            <Input type="text" placeholder='Pseudo' onChange={handlePseudoChange} ></Input>
            <br/>
            <Button onclick={changePseudo} texte='Rejoindre'></Button>
            </> ) : 
            (
            <>
            <p className={style.waiting}>Pseudo : {pseudo}</p>
            <p className={style.waiting}>En attente du lancement de l'hôte</p>
            
            <span className={style.loader}></span>
            
            <div className={style.playerBox}>
                <div className={style.adaptativeBox}>
                    {players.map((player,index) =>{
                        return (
                            <PlayerContainer key={"player" + index} playerName={`${player.name===pseudo  ? "Vous" :  player.name}`}>
                                                    </PlayerContainer>)
                    })}
                </div>
            </div>
            </>)}
        </div>

    </div>
  )
}

export default Waitting