import React, { useState } from 'react';
import style from './validationBox.module.css';
import Button from '../button/Button';
type propsTypes = {

  isOpen: boolean;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}


function ValidationBox({ isOpen, message, onConfirm, onCancel }: propsTypes) {
  if (!isOpen) {
    return null;
  }
  return (
    <div className={style.container}>

      <p>{message}</p>
      <div className={style.button}>
        <Button onclick={onCancel} texte="Annuler" className={style.buttonCancel} />
        <Button onclick={onConfirm} texte="Confirmer" className={style.buttonConfirm} />

      </div>
    </div>

  );

}
export default ValidationBox