import React from 'react';
import style from './title_classement.module.css';

type propsTypes = {
    title: string,
    className?:string,
    
}

function Title_Classement({title,className}:propsTypes) {
  return (
    <div className={`${style.container} ${className}`}>

        <div className={style.text}>
        <p >{title}  </p>
        </div>
    
    </div>
  )
}

export default Title_Classement