import React, { useState } from 'react';
import style from './questionItem.module.css';
import ValidationBox from '../validationBox/ValidationBox';

type propsTypes = {
    index?:number,
    question: string,
    type: number,
    className?:string,
    edit_btn : (id?:number)  => void,
    delete_btn : (id?:number)  => void,
}



function QuestionnaireItem({index,question,type,edit_btn,delete_btn,className}:propsTypes) {

  const [isDialogOpen, setDialogOpen] = useState(false);
  const handleOpenDialog = () => {
      setDialogOpen(true);
  };
  const handleCancel = () => {
      // Logique à exécuter lors de l'annulation
      console.log('Action annulée');
      setDialogOpen(false);
  };


  return (
    <div className={style.container}>
    <div className={`${style.questionnaire} ${className}`}>
      <div className={style.titreQuestionnaire}>
          {question}
      </div>
      <div className={style.nb_quesionQuestionnaire}>
            {type == 0 ? "QCM" : "Ouverte" }
      </div>
      <div className={style.buttonsQuestionnaire}>
          <button className={style.Btn} onClick={() => edit_btn(index)}><img src='/img/icon_edit.svg'></img></button>
          <button className={`${style.Btn} ${style.red}`} onClick={handleOpenDialog}><img src='/img/icon_delete.svg'></img></button>
      </div>
  </div>
   <ValidationBox isOpen={isDialogOpen} message="Voulez vous vraiment supprimer cette question ?" onConfirm={() => delete_btn(index)} onCancel={handleCancel} />

   </div>
  )
}

export default QuestionnaireItem