import React from 'react'
import style from './barGraph.module.css';

type propsType = {
  stats: number[],
}

function BarGraph({stats}:propsType) {
  return (

    <div className={style.container}>
      
      {stats.map((value,index)=>{
        return (
          <div key={`keyof${index}`} className={style.barOut} >
            <p className={style.text}>{parseFloat(value.toFixed(1))}%</p>
            <div className={style.barIn} style={{height:`${value}%`}}>

            </div>
          </div>
        )
      })}

    </div>
  )
}

export default BarGraph