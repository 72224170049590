import React from 'react'
import style from './button.module.css'

type propsType={
    texte?: string,
    className?:string,
    onclick ?: () => void
}

function Button({texte,className,onclick}:propsType) {
  return (
    <button className={`${style.button} ${className}`} onClick={onclick}>{texte}</button>
  )
}

export default Button