import React from 'react'
import style from './playerContainer.module.css'

type propsType = {
    playerName: string,
    children?: React.ReactNode; 
}

function PlayerContainer({playerName}:propsType) {
  return (
    <div className={style.playerBox}>
        <p className={style.text}>{playerName}</p>
    </div>
  )
}

export default PlayerContainer