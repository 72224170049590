import React from 'react'
import style from './inputform.module.css';

type propsType = {
    placeholder?: string 
    className?: string
    type?: string
    icon?:string
    alt?:string
    name?:string
    id?:string
    value?:string
    onChange?:(value:string)=>void
    onKeyDown?:(event: React.KeyboardEvent<HTMLInputElement>)=>void
    inputMode?: "search" | "text" | "email" | "tel" | "url" | "none" | "numeric" | "decimal"

}

function InputForm({placeholder,className,type,icon,name,id,alt,value,onChange,onKeyDown,inputMode}:propsType) {
  return (
    <div className={`${style.inputform} ${className}`}>
        <img src={icon} alt={alt} />
        <input type={type} placeholder={placeholder} name={name} id={id} value={value} onChange={(e)=>{!onChange ? undefined : onChange(e.target.value)}} onKeyDown={onKeyDown} inputMode={inputMode}/>
    </div>
  )
}

export default InputForm