import { io,Socket } from 'socket.io-client';
import { BASE_URL_socket } from '../../constants';
import { ServerToClientEvents,ClientToServerEvents,InterServerEvents } from '../type/Socket';


// "undefined" means the URL will be computed from the `window.location` object
const URL = BASE_URL_socket

export const socket : Socket<ServerToClientEvents, ClientToServerEvents> = io(URL,{
    autoConnect:false,
    reconnectionAttempts:2,
    requestTimeout:2000
});
