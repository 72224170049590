import React from 'react'
import style from './classementBox.module.css'

type propsTypes = {
    utilisateur? : string,
    score? : number,
    classement? : number,
    className? : string,
}

function ClassementBox({classement,utilisateur,score,className}:propsTypes) {
  return (
    <div className={utilisateur ? `${style.container} ${className}` :`${style.emptyContainer} ${className}` }>
        <div className={style.classement}><p className={style.text}>{classement}</p></div>
        <div><p className={style.text}>{utilisateur}</p></div>
        <div className={style.score}><p className={style.text}>{score}</p></div>
    </div>
  )
}

export default ClassementBox