import React,{useEffect} from 'react'
import style from './login.module.css';
import InputForm from '../../components/inputform/InputForm';
import Button from '../../components/button/Button';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../components/auth/AuthContext';
import { useContext,useState } from 'react';
import { User } from '../../components/type/User';
import { postData } from '../../components/API/function';
import { CustomResponse } from '../../components/type/Response';

import { Store } from 'react-notifications-component';

export const Login = () => {
  
  const {user,setUser} = useContext(AuthContext)

  const [mail,setMail] = useState<string>("")
  const [password,setPassword] = useState<string>("")
  const navigate = useNavigate();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      logUser();
    }
  };

  async function logUser(notif=true){

    if(mail === "" || password === ""){
      if(notif){
        Store.addNotification({
          title: "Erreur format",
          message: "Au moins un des champs est vide",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        return
      }
    }

    await postData<User | CustomResponse>('/login',{mail:mail,password:password}).then((resp)=>{
        if (!(resp as CustomResponse).message){
          const respUser = resp as User
          setUser({mail:respUser?.mail,login:respUser?.login,_id:respUser?._id});
        
          // Fonction pour changer de page vers "Mainpage"
          navigate('/mainpage');
        }
        else{
          if (notif){
          console.log((resp as CustomResponse).message)
          Store.addNotification({
            title: "Erreur de connexion",
            message: "L'email ou le mot de passe est incorrect",
            type: "danger",
            insert: "top",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
        }}
        
    }).catch((e)=>{
      console.log(e)
    })
  }

  useEffect(()=>{
    logUser(false)
  },[])

  return (
    <div className={style.container}>
        <div className={style.header}>
            <div className={style.text}>Connexion</div>
            <div className={style.underline}></div>
        </div>
        <div className={style.inputs}>
            <InputForm placeholder='Email' icon='/img/email_icon.svg' name='email' type='email' alt="email logo" value={mail} onChange={setMail} onKeyDown={handleKeyPress} inputMode="email"></InputForm>
            <InputForm placeholder='Mot de passe' icon='/img/password_icon.svg' name='password' type='password' alt="cadena logo" value={password} onChange={setPassword} onKeyDown={handleKeyPress}></InputForm>
        </div>
        <div className={style.forgot_password}>
           {/* <a href='/resetpwd'>Mot de passe oublié ?</a> */}
        </div>
        
        <Button className={style.submit}  onclick={logUser} texte='Connexion'></Button>

        <div className={style.no_account}>  
        Pas encore inscrit ? <Link to="/signup">Créer un compte !</Link>
        </div>
    </div>
  )
}

export default Login