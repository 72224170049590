// Importez les modules nécessaires
import  {ReactNode} from 'react';
import Home from '../home/Home';
import Launcher from '../launcher/Launcher';
import Login from '../login/Login';
import Signup from '../signup/Signup';
import MainPage from '../mainPage/MainPage';
import { BrowserRouter as Router, Route, Routes, Navigate , Outlet} from 'react-router-dom';
import Waitting from '../waitting/Waitting';
import QuestionnaireEdit from '../questionnaireEdit/QuestionnaireEdit';
import Game from '../game/Game';
import Classement from '../classement/Classement';
import { AuthContext } from '../../components/auth/AuthContext';
import { useContext } from 'react';
import { User } from "../../components/type/User"
import DisplayQuestion from '../displayQuestion/DisplayQuestion';


const player1 = {name:"Alexis",score:1000}
const player2 = {name:"Maxime",score:200}
const questionn = {_id: "", id_questionnaire:"", question: "Qui est le meilleur développeur de l'équipe ?", reponse: ['Nicolas','Alexis','maxime','mael'], reponse_juste: ['Nicolas']}


type userContext = User|undefined


const ProtectedRoute = ({children,user}:{children:ReactNode,user:userContext})=>{
  return (
    <>
    {user !== undefined ? children:  <Navigate to="/" replace />}
    </>
  )
  }

// Créez une fonction de navigation
const Navigation = () => {
  const {user,setUser} = useContext(AuthContext)
  return (
    <Router>
        <Routes>
            <Route path="/" element={<Home></Home>}></Route>
            <Route path="/mainpage" element={<ProtectedRoute user={user}><MainPage></MainPage></ProtectedRoute>}></Route>
            <Route path="/login" element={<Login></Login>}></Route>
            <Route path="/signup" element={<Signup></Signup>}></Route>
            <Route path="/game/:gameCode" element={<Game></Game>}></Route>
            <Route path="/launcher" element={<ProtectedRoute user={user}><Launcher></Launcher></ProtectedRoute>}></Route>
            <Route path="/questionnaire" element={<ProtectedRoute user={user}><QuestionnaireEdit></QuestionnaireEdit></ProtectedRoute>}></Route>
            <Route path="/waitting/:gameCode" element={<Waitting></Waitting>}></Route>
            <Route path="/classement" element={<Classement tab_classement={[player1,player2 ]}></Classement>}></Route>
            <Route path="/question"  element={<DisplayQuestion question={questionn} player_id=''  ></DisplayQuestion>}></Route>
        </Routes>
    </Router>
  );
};

export default Navigation;


