import React, { useState, useEffect } from "react";
import style from "./timer.module.css";
type propsTypes = {
  nombre_secondes: number;
  onTimerTick?: (seconds: number) => void;
  onTimerEnd: () => void;
};

function Timer({ nombre_secondes, onTimerTick, onTimerEnd }: propsTypes) {
  const [seconds, setSeconds] = useState<number>(nombre_secondes);
  const radius = 40; // rayon du cercle
  const circumference = 2 * Math.PI * radius; // circonférence du cercle

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
      if (onTimerTick) {
        onTimerTick(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, onTimerTick]);

  useEffect(() => {
    if (seconds === 0) {
      onTimerEnd(); // Appeler la fonction fournie lorsque le timer atteint zéro
    }
  }, [seconds, onTimerEnd]);

  const calculateGradient = () => {
    // Calculer le pourcentage du dégradé conique en fonction du temps restant
    const gradientPercentage =
      ((nombre_secondes - seconds) / nombre_secondes) * 100;
    return `conic-gradient(
          #e74c3c ${gradientPercentage}%, 
          #eae6e6 ${gradientPercentage}% 100%
        )`;
  };

  return (
    <div className={style.timer_container}>
      <div
        className={style.timer_circle}
        style={{ background: calculateGradient() }}
      ></div>
      <h1 className={style.timer_text}>{seconds}s</h1>
    </div>
  );
}

export default Timer;
