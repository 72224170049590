import { useEffect, useState } from 'react'
import style from './Launcher.module.css'
import PlayerContainer from '../../components/playerContainer/PlayerContainer'
import Button from '../../components/button/Button'
import {  useNavigate,useLocation } from 'react-router-dom';
import { socket } from '../../components/API/socket';
import { postData } from '../../components/API/function';
import { CustomResponse } from '../../components/type/Response';
import { Question } from '../../components/type/Question';
import {Player} from '../../components/type/Player'
import QRCode from "react-qr-code";
import QuestionBox from '../../components/questionBox/QuestionBox';
import { Store } from 'react-notifications-component';
import DisplayQuestion from '../displayQuestion/DisplayQuestion';
import DisplayResponse from '../displayResponse/DisplayResponse';
import Classement from '../classement/Classement';


function Launcher() {

//const players : string[] = ["Joueur 1", "Joueur 2", "Joueur 3","Joueur 4", "Joueur 5", "Joueur 6","Joueur 7"]
const [players,setPlayer] = useState<Player[]>([])
const [gameCode,setGameCode] = useState<string>("")
const [questions,setQuestions] = useState<Question[]>([])


const [question,setQuestion] = useState<Question>({_id: "", id_questionnaire:"", question: "Qui est le meilleur développeur de l'équipe ?", reponse: ['Nicolas','Alexis'], reponse_juste: ['Nicolas']})
const [stats,setStats] = useState<number[]>([0,0,0,0])

const [scores,setScores] = useState<Player[]>([])


const [currentQuestion,setCurrentQuestion] = useState<number>(0)
const [displayAnswer,setDisplayAnswer] = useState<boolean>(true)
const [isFullScreen, setIsFullScreen] = useState(false);

const [isStarted,setIsStarted] = useState<boolean>(false)
const [isFinish,setIsFinish] = useState<boolean>(false)
const [isResponse,setIsReponse] = useState<boolean>(false)

const navigate = useNavigate();
const location = useLocation();
const receivedData = location.state;

const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
};

const StartQuizz = async () : Promise<void> => {
    
    socket.emit('start_game',{gameCode: gameCode});

    setQuestion(questions[currentQuestion])


    setIsStarted(true);
    
    //navigate('/questions')
;}

const getQuestion = async () : Promise<Question[]> =>{
    const ret = await postData<CustomResponse | Question[]>('/questions',{id_questionnaire:receivedData.questionnaireId}).then((resp)=>{
        
        if(!(resp as CustomResponse).message ){
            setQuestions(resp as Question[])
             return resp as Question[]
        }else{
            console.log((resp as CustomResponse).message)
            return []
        }
    });
    return ret
}

const NextQuestion = async () : Promise<void> => {
    if(currentQuestion < questions.length){
        if(!displayAnswer){
            setDisplayAnswer(true)
            socket.emit('set_question',{gameCode: gameCode});
            setCurrentQuestion((prevQuestion) => prevQuestion + 1);
            
        }else{
            setDisplayAnswer(false)
            socket.emit('set_answer',{gameCode: gameCode});
        }
       
    }/*else{
        if(displayAnswer){
            setDisplayAnswer(false)
            socket.emit('set_answer',{gameCode: gameCode});
        }else{
            socket.emit('end_game',{gameCode: gameCode,scores:players});
            console.log("test")
            setTimeout(() => {
                navigate('/mainpage')
            },10000)
        }
        
    }*/
}

const generateCode = ():string => {
    let code : string  =""
    for (let i = 0;i<3;i++){
        for(let j = 0;j<3;j++){
            code += Math.floor(Math.random()*10).toString()
        }
        if(i!=2){
        code += "-"}
    }
    return code
}

const handleCopy = () => {
    navigator.clipboard.writeText(window.location.origin+"/game/"+gameCode)
    
    Store.addNotification({
        title: "Lien copié",
        message: "Le lien de la partie a été copié!",
        type: "success",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });


}
const handleShare = async () => {
    try {
      await navigator.share({
        title: 'Participe au Quizz !',
        text: 'Rejoins moi sur le quizz : window.location.origin+"/game/"+gameCode',
        url: window.location.origin+"/game/"+gameCode
      });
      //console.log('Partage réussi');
    } catch (e:any) {
      console.error('Erreur de partage :', e.message);
    }
  };



useEffect(()=>{


    const code : string = generateCode()
    setGameCode(code)
    socket.connect()

    getQuestion().then((resp)=>{
        socket.emit("host_join",{gameCode: code,questions:resp,questionnaire:receivedData.questionnaireTitre});
    })
        
    
    socket.on("player_join",(data)=>{
        setPlayer(data.players);
        console.log(players)
    })


    socket.on("end_game",(data)=>{
        console.log("End game :", data.scores);
        setScores(data.scores);
        setIsFinish(true);
        setTimeout(()=>{
          navigate('/mainpage')
        },10000)
      })

    socket.on("set_question",(data)=>{
        setIsReponse(false)
        setDisplayAnswer(true)
        setQuestion(data.currentQuestion);
        console.log(data.currentQuestion)
    })

    socket.on("set_answer",(data)=>{
        setIsReponse(true)
        setDisplayAnswer(false)
        setStats(data.stats)
      })




    return ()=>{
        socket.off("player_join")
        socket.off('end_game')
    }
},[])

  return (
    <div className={style.background}>
        
        {isFinish ? (
            <Classement tab_classement={scores}/>
        ) : (
        
        isStarted ? (
            isResponse ? (
                <div>                  
                    <DisplayResponse question={question} stats={stats}/> 
                    <Button className={style.nextBtn} onclick={NextQuestion} texte='SUIVANT ->'></Button>
                </div>
            ) : (
                <div>                  
                    <DisplayQuestion question={question} />
                    <Button className={style.nextBtn} onclick={NextQuestion} texte='SUIVANT ->'></Button>
                </div>
            )            
        ) : (

            <div className={style.container}>
                <QuestionBox className={style.questionBox} question={receivedData.questionnaireTitre}></QuestionBox>  

                <div className={` ${style.box} ${style.transparent} `}>
                    <div className={style.linkSection}>
                        <div className={style.link}>
                            <div>Rejoignez la partie sur <a href="/">thequizz.me</a></div>
                            <div className={style.code} onClick={handleCopy}>
                                {gameCode}
                                <button className={style.copyBtn}>
                                    <img src='/img/copy.svg' alt="Copy image" />
                                </button>
                            </div>
                            <Button className={style.shareBtn} onclick={handleShare} texte='Partager'></Button>
                        </div>
                            <div style={{
                                position: isFullScreen ? 'fixed' : 'relative',
                                top: isFullScreen ? 0 : 'auto',
                                left: isFullScreen ? 0 : 'auto',
                                width: isFullScreen ? '100%' : 'auto',
                                height: isFullScreen ? '100vh' : 'auto',
                                backgroundColor: isFullScreen ? 'rgba(0, 0, 0, 0.8)' : 'transparent',
                                zIndex: isFullScreen ? 100 : 'auto',
                                display: isFullScreen ? 'flex' : 'block',
                                flexDirection: isFullScreen ? 'column' : 'column',
                                justifyContent: isFullScreen ? 'center' : 'initial',
                                alignItems: isFullScreen ? 'center' : 'initial',
                                cursor: 'pointer',
                            }} onClick={toggleFullScreen}>
                            <QRCode
                                size={isFullScreen ? 512 : 256}
                                value={window.location.origin + '/game/' + gameCode}
                                style={{
                                    width: isFullScreen ? '80vw' : 'auto',
                                    height: isFullScreen ? 'auto' : 'auto',
                                    maxWidth: isFullScreen ? '80vh' : '200px',
                                    margin: isFullScreen ? 'auto' : '0',
                                    display: isFullScreen ? 'block' : 'inline-block',
                                }}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                    </div>
                </div> 
                <div className={style.box}>
                    <div className={style.header}>
                        <div>Joueurs</div>
                    </div>
                    <div className={style.underline}></div>
                    <div className={style.adaptativeBox}>
                        {players.length === 0 ? (
                            <div className={style.noplayer}>Aucun joueur pour le moment...</div>
                        ) : (
                            players.map((player,index) =>{
                                return (
                                    <PlayerContainer key={"player"+index} playerName={player.name}></PlayerContainer>
                                )
                            })
                        )}
                    </div>
                </div> 
                <div className={`${style.box} ${style.transparent} `}>
                    <div className={style.linkSection}>
                        <Button className={style.Btn} onclick={StartQuizz} texte='DEMARRER'></Button>
                        {/*<Button className={style.Btn} onclick={GoQuestion} texte='DEMARRER'></Button>
                        <Button className={style.Btn} onclick={NextQuestion} texte='SUIVANTE'></Button>*/}
                    </div>
                </div>
            </div>
        ))}
    </div>       
  )
}

export default Launcher