import style from './classement.module.css'
import Title_Classement from '../../components/title_Classement/Title_classement'
import ClassementBox from '../../components/classementBox/ClassementBox'
import {Player} from '../../components/type/Player'

type propsTypes = {
  tab_classement : Player[]
}
function Classement({tab_classement}:propsTypes) {

  console.log(tab_classement)
  if(tab_classement === undefined){
    tab_classement = [{name: 'Nicolas',score :10000},{ name: 'Baptiste le BG', score: 800 },{ name: 'Maël', score: 600 },{ name: 'Alexis', score: 800 },{ name: 'Alexis', score: 800 }]
  }

  return (

    <div className={style.background}>
        
        <div className={style.container}>

        <Title_Classement className={style.Title} title="Classement" ></Title_Classement>

        </div>

        <div className={style.classementcontainer}>
                    
        {tab_classement.sort((player1,player2)=>player2.score-player1.score).map((user,index) => (
            
            <ClassementBox key={user.id} className={index == 0 ?  style.classementBox_first : index == 1 ? style.classementBox_second :  index == 2 ? style.classementBox_third : style.classementBox} classement={index +1} utilisateur={user.name} score={user.score}></ClassementBox>

        ))}
      
            </div>
    </div>
  )
}

export default Classement