import React from 'react'
import style from './input.module.css';

type propsType = {
    placeholder?: string 
    className?: string
    type?: string
    onChange?:(value:string)=>void
    value?:string
    onKeyDown?:(event: React.KeyboardEvent<HTMLInputElement>)=>void
    inputMode?: "search" | "text" | "email" | "tel" | "url" | "none" | "numeric" | "decimal"
}

function Input({placeholder,className,type,onChange,value,onKeyDown,inputMode}:propsType) {
  return (
    <input  className={`${style.input} ${className}`} type={type} placeholder={placeholder} value={value} onChange={(e)=>{!onChange ? undefined : onChange(e.target.value)}  } onKeyDown={onKeyDown} inputMode={inputMode}></input>
  )
}

export default Input