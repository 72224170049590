import React, { useState } from "react";
import style from './popup.module.css';
import QuestionBox from '../../components/questionBox/QuestionBox'
import Button from '../button/Button';
import { Question } from '../../components/type/Question'

type propsTypes = {
    question: Question | null; 
    className?:string,
    exit_btn ?: () => void,
    save_btn: (newQuestion: Question) => void;
}

/*type Reponse= { id:number; reponse:string; correct:boolean}

type Question = {
    id: number;
    question: string;
    type: number;
    reponses: Reponse[];
};*/

/*var question:Question = {id:0,
    question:'Qui est le meilleur développeur ?',
    type:0,
    reponses:[
        {id:0,reponse:'Maël',correct:true},
        {id:1,reponse:'Nicolas',correct:false}]
}*/


function Popup({question,className,exit_btn,save_btn}:propsTypes) {

    if (question == undefined) {
        question = {_id:"", id_questionnaire:"", question:"",reponse:[],reponse_juste:[]}
    }

    const [data, setData] = useState(question);
    
    const checkNbReponses = () => {
        if (/*data.type == 0 && */data.reponse.length<=1) {
            const updatedReponses = [...data.reponse];
            for(let i =updatedReponses.length; i<2;i++) {
                updatedReponses.push("")
            }
            setData({ ...data, reponse: updatedReponses });
        }
    }


    checkNbReponses();


    const AddReponse = () => {
        if(data.reponse.length >= 4){
            console.log("Impossible d'ajouter plus de réponses");
            return;
        }
      
        const updatedReponses = [...data.reponse];
        updatedReponses.push("")
        const updatedQuestion = { ...data, reponse: updatedReponses }; 
        setData(updatedQuestion);
    }

    const DelReponse = (id:number) => {
        if(data.reponse.length <= 2){
            console.log("Impossible de supprimer plus de réponses");
            return;
        }

        let updatedReponses = [...data.reponse];
        let updatedReponsesJuste = [...data.reponse_juste];

        //delete from reponse_juste
        if(data.reponse_juste.includes(data.reponse[id])){
            updatedReponsesJuste = updatedReponsesJuste.filter(e => e !== data.reponse[id]);
        }
        updatedReponses.splice(id,1);

        const updatedQuestion = { ...data, reponse: updatedReponses, reponse_juste:updatedReponsesJuste}; 
        setData(updatedQuestion);

    }

    const handleChangeQCMType = (event: React.ChangeEvent<HTMLSelectElement>) => {        
        /*const updatedQuestion = { ...data, type: Number(event.target.value)}; 
        setData(updatedQuestion);*/

        checkNbReponses();

        //console.log(data)
    };

    const handleChangeQuestion = (value:string) => {
        //console.log("Changed question name : ");
        /*const updatedQuestion = data; 
        updatedQuestion.question = value;
        setData(updatedQuestion);*/
        setData(prevQuestion => ({
            ...prevQuestion,
            question: value
        }));
        //console.log(data);
    };

    const handleChangeText = (id:number, event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
    
        const updatedReponses = [...data.reponse]; 
        updatedReponses[id] = value;
    
        const updatedQuestion = { ...data, reponse: updatedReponses }; 
        setData(updatedQuestion);
        //console.log(data)
    };

    const handleChangeCheck = (id:number, event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
    
        let updatedReponsesJuste = data.reponse_juste;

        if(checked){
            //add reponse in reponse_juste
            if(!data.reponse_juste.includes(data.reponse[id])){
                updatedReponsesJuste.push(data.reponse[id])
            }
        }else{
            //remove reponse in reponse_juste
            if(data.reponse_juste.includes(data.reponse[id])){
                updatedReponsesJuste = updatedReponsesJuste.filter(e => e !== data.reponse[id]);
                
            }
            console.log(updatedReponsesJuste);
        }
    
        const updatedQuestion = { ...data, reponse_juste: updatedReponsesJuste }; 
        setData(updatedQuestion);
    };

    const addQuestion = () => {
        console.log("Add question");
        //console.log(data);
        save_btn(data);
      };

    

    return(

        <div className={style.popup_container}>
            <div className={style.popup}>
                <div className={style.header}>
                    <QuestionBox question={data.question} onchange={handleChangeQuestion} className={style.questionBox} type="edit" placeHolder="Entrez une question"></QuestionBox>
                    <button className={style.exit_btn} onClick={exit_btn}>╳</button>
                </div>
                <div className={style.content}>
                    {/*<div className={style.typeQuest}>Type de question :
                        <select name="questionType" id="questionType" onChange={handleChangeQCMType} defaultValue={data.type}>
                            <option value="0">QCM</option>
                            <option value="1">Ouverte</option>
                        </select>
                    </div>*/}
                    <div className={style.typeQuest}>
                        <div className={style.headerRep} >
                            <div>Réponses :</div>
                            <button className={style.addBtn} onClick={AddReponse} disabled={data.reponse.length>=4}>+ Ajouter</button>
                            <div className={style.correct}>Correcte</div>
                        </div>
                        
                        {data.reponse.map((reponse, index) => (
                            <div className={style.reponse} key={index}>
                                <button className={style.deleteBtn} onClick={() => DelReponse(index)} disabled={data.reponse.length<=2}>✖</button>
                                <div>{index + 1}.</div>
                                <input type='text' placeholder="Entrez une réponse..." value={reponse} onChange={(event) => handleChangeText(index, event)}></input>
                                <input type='checkbox' checked={data.reponse_juste.includes(reponse)} onChange={(event) => handleChangeCheck(index, event)}></input>
                            </div>
                        ))}
                        
                        
                    </div> 
                    <Button texte={question._id ? "Sauvegarder":"Ajouter la question"} className={style.saveBtn2} onclick={addQuestion}></Button>
                </div>
            </div>      
        </div>



    )
}

export default Popup
