import React, { useState } from 'react'
import style from './home.module.css';
import Input from '../../components/input/Input';
import Button from '../../components/button/Button';
import { useNavigate } from 'react-router-dom';
import { Store } from 'react-notifications-component';


function Home() {


  const [code, setCode] = useState<string>("")
  const [formattedCode, setFormattedCode] = useState<string>("");
  const navigate = useNavigate();

  const Gologgin = (): void => {
    navigate('/login')
      ;
  }
  const formatCode = (input: string): string => {
    // Supprimer les caractères non numériques du code
    const numericCode = input.replace(/\D/g, '');
  
    // Ajouter les tirets
    const formattedCode = numericCode.replace(/(\d{3})(?=\d)/g, '$1-');

    return formattedCode;
  };

  const handleCodeChange = (value: string) => {
    // Limiter le code à 9 caractères
    const limitedValue = value.slice(0, 11);
    setCode(limitedValue); // Mettre à jour le code brut
    const newFormattedCode = formatCode(limitedValue); // Formater le code
    setFormattedCode(newFormattedCode); // Mettre à jour le code formaté
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      joinGame();
    }
  };


  const joinGame = (): void => {
    if (code) {
      navigate('/game/' + code);
    } else if (!code) {
      Store.addNotification({
        title: "Code invalide",
        message: "Le code ne peut pas être vide.",
        type: "danger",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }


  return (
    <div className={style.homeBackground}>
      <div className={style.center}>
        <div>
          <img src='/img/logo.svg' width={250}></img>
        </div>
        <div>
          <p className={style.phrase}>Crée ou participe à des quizzs à plusieurs</p>
        </div>
        <div className={style.box}>

          <div className={style.flexColumn}>
            <Input className={style.inputPIN} type="text" placeholder='Code PIN' value={formattedCode} onChange={handleCodeChange} onKeyDown={handleKeyPress} inputMode='numeric' ></Input>
            <Button className={style.btnJoin} onclick={joinGame} texte='Rejoindre'></Button>
          </div>
          <Button className={style.buttonCreate} onclick={Gologgin} texte='Crée ton propre quizz'></Button>
        </div>
      </div>

    </div>
  )
}

export default Home