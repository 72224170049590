import { useEffect,useState} from 'react'
import style from './questionnaireEdit.module.css'
import QuestionBox from '../../components/questionBox/QuestionBox'
import Button from '../../components/button/Button'
import QuestionItem from '../../components/questionItem/QuestionItem'
import Popup from '../../components/popup/Popup'
import { useNavigate, useLocation } from 'react-router-dom';
import { postData } from '../../components/API/function'
import { CustomResponse } from '../../components/type/Response'
import { Question } from '../../components/type/Question'
import { Questionnaire } from '../../components/type/Questionnaire'
import { Store } from 'react-notifications-component';


function QuestionnaireEdit() {
  
  const navigate = useNavigate();
  const location = useLocation();
  const receivedData = location.state;

  const [showPopup, setshowPopup] = useState(false);  
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>({ _id:"",name:"",id_createur:""});
  const [questions, setQuestions] = useState<Question[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(null);

  useEffect(()=>{
    //get id_qestionnaire from url if exists for edit
    if (receivedData && 'id_questionnaire' in receivedData) {
      let updatedQuestionnaire = questionnaire;
      updatedQuestionnaire._id = receivedData.id_questionnaire;
      setQuestionnaire(updatedQuestionnaire);
      
      getQuestionnaire();
      getQuestions();
    }else if (receivedData && 'name_questionnaire' in receivedData) {
      let updatedQuestionnaire = questionnaire;
      updatedQuestionnaire.name = receivedData.name_questionnaire;
      handleQuestionnaireChange(receivedData.name_questionnaire);
      //console.log(questionnaire.name);
    }
  },[])
                  
  //API FUNCTIONS 
  async function getQuestionnaire() : Promise<void>{
    if(!questionnaire._id || questionnaire._id === "" ){
      console.log("Can't get questionnaire if id is not defined")
      return;
    }
    await postData<CustomResponse | Questionnaire>('/questionnaire',{id_questionnaire:questionnaire._id}).then((resp)=>{
      //console.log("Reponse getQuestionnaire : ", resp as Questionnaire)
      if (!(resp as CustomResponse).message ) {
        setQuestionnaire(resp as Questionnaire);
        handleQuestionnaireChange((resp as Questionnaire).name);
      }else{
        console.log((resp as CustomResponse).message)
      }
    });
  }

  async function addQuestionnaire() : Promise<void>{
    await postData<CustomResponse | Questionnaire>('/addQuestionnaire',{name_questionnaire:questionnaire.name}).then((resp)=>{
      if (!(resp as CustomResponse).message ) {
        Store.addNotification({
          title: "Questionnaire créé",
          message: "Le questionnaire a bien été créé",
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        //console.log("Questionnaire ajouté : ", resp as Questionnaire);
        setQuestionnaire(resp as Questionnaire);
        return resp as Questionnaire;
      }else{
        console.log((resp as CustomResponse).message)
        Store.addNotification({
          title: "Erreur !",
          message: "Le questionnaire n'a pas pu être ajouté",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    });
  }

  async function updateQuestionnaire() : Promise<void>{
    await postData<CustomResponse | Question[]>('/editquestionnaire',{id_questionnaire:questionnaire._id,name_questionnaire:questionnaire.name}).then((resp)=>{
      if (!(resp as CustomResponse).message ) {
        console.log("Impossible de mettre à jour le questionnaire!")
        Store.addNotification({
          title: "Erreur !",
          message: "Le questionnaire n'a pas pu être mis à jour",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }else{
        console.log((resp as CustomResponse).message)
        Store.addNotification({
          title: "Questionnaire mis à jour",
          message: "Le questionnaire a bien été mis à jour",
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
      getQuestionnaire();
    })
  }

  async function getQuestions() : Promise<void>{
    if(!questionnaire._id || questionnaire._id === "" ){
      console.log("Can't get questions if id_questionnaire is not defined")
      return;
    }
    await postData<CustomResponse | Question[]>('/questions',{id_questionnaire:questionnaire._id}).then((resp)=>{
      //console.log("Reponse getQuestions : ", resp as Question[])
      if (!(resp as CustomResponse).message ) {
        setQuestions(resp as Question[])
      }else{
        console.log((resp as CustomResponse).message)
      }
    })
  }


  async function addQuestion(question:Question) : Promise<void>{
    if(!questionnaire._id || questionnaire._id === "" ){
      console.log("Impossible d'ajouter une question si l'id du questionnaire n'est pas défini");
    }
    await postData<CustomResponse | Question[]>('/addQuestion',{
      id_questionnaire :questionnaire._id,
      question :question.question,
      reponse :question.reponse,
      reponse_juste:question.reponse_juste}
      ).then((resp)=>{
        console.log((resp as CustomResponse).message)
        if((resp as CustomResponse).message === "Question ajoutée"){
          Store.addNotification({
            title: "Question ajoutée",
            message: "La question a bien été ajoutée au questionnaire",
            type: "success",
            insert: "top",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
          getQuestions();
        }else if ((resp as CustomResponse).message === "Titre vide"){
          Store.addNotification({
            title: "Titre vide !",
            message: "Le titre de la question ne peut pas être vide.",
            type: "danger",
            insert: "top",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });

         } else  {
        console.log("Impossible d'ajouter une question")
        console.log((resp as CustomResponse).message)
        Store.addNotification({
          title: "Erreur !",
          message: "La question n'a pas pu être ajoutée",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    })
  }

  async function editQuestion(question:Question) : Promise<void>{
    await postData<CustomResponse | Question[]>('/editQuestion',{
      id_question:question._id,
      id_questionnaire :questionnaire._id,
      question :question.question,
      reponse :question.reponse,
      reponse_juste:question.reponse_juste}
      ).then((resp)=>{
      if (!(resp as CustomResponse).message ) {
        console.log("Impossible de mettre à jour une question!")
        Store.addNotification({
          title: "Erreur !",
          message: "La question n'a pas pu être mise à jour",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }else{
        console.log((resp as CustomResponse).message)
        Store.addNotification({
          title: "Question mise à jour",
          message: "La question a bien été mise à jour",
          type: "success",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
      getQuestions();
    })
  }

  async function deleteQuestion(id:string) : Promise<void>{
    await postData<CustomResponse>("/removeQuestion",{id_questionnaire:questionnaire._id,id_question:id}).then((resp)=>{
      if (resp.message !=='Question supprimer'){
          console.log(resp.message)
          Store.addNotification({
            title: "Erreur ! ",
            message: "La question n'a pas pu être supprimée",
            type: "danger",
            insert: "top",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
      }else {
      Store.addNotification({
        title: "Question supprimée",
        message: "La question a bien été supprimée",
        type: "success",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      }); }
      getQuestions();
    }) 
  }
  
  function tooglePopup(){
    setshowPopup(!showPopup);
  }
 

  const newQuestion = () => {
    if(!questionnaire._id || questionnaire._id === "" ){
      addQuestionnaire();
    } 
    console.log("Création d'une nouvelle question");
    setSelectedQuestion(null);
    tooglePopup();
  }

  function editQuestionBtn(index:number){
    console.log("Edit question n°"+index);
    setSelectedQuestion(questions[index]);
    tooglePopup();
  }

  const saveQuestion = (question:Question) => {
    if(selectedQuestion == null){
      //save new question
      addQuestion(question);
    }else{
      //edit question
      editQuestion(question);
    }
    tooglePopup();
  };

  const handleQuestionnaireChange = (value:string) => {
    //console.log("Changed questionnaire name : ");
    setQuestionnaire(prevQuestionnaire => ({
      ...prevQuestionnaire,
      name: value
    }));
  }

  const saveQuestionnaire = async () => {
    if(!questionnaire._id || questionnaire._id === ""){
      await addQuestionnaire();
    } else{
      await updateQuestionnaire();
    }
    handleGoBack();
  }

  const handleGoBack = () => {
    navigate('/mainpage')
  }
  

  return (
    <div className={style.background}>

        {showPopup && <Popup question={selectedQuestion} exit_btn={tooglePopup} save_btn={saveQuestion} />}

        <div className={style.container}>
            <QuestionBox question={questionnaire.name} placeHolder='Entrez le nom du questionnaire...' onchange={handleQuestionnaireChange} className={style.questionBox} type="edit"></QuestionBox>
            <div className={style.box}>
                <div className={style.header}>
                    <div>Questions</div>
                    <button className={style.addBtn} onClick={newQuestion}><img src='/img/add.svg'></img> Ajouter</button>

                </div>
                <div className={style.underline}></div>
                <div className={style.questionnaireSection}>
                    <div className={style.questionnaireList}>

                        {questions.length>0 ? questions.map(function(question,index){
                            return <QuestionItem key={index}  question={question.question} type={0} delete_btn={() => deleteQuestion(question._id)} edit_btn={() => editQuestionBtn(index)}></QuestionItem>
                        })
                        : <div className={style.noQuestion}>Aucune question</div>
                        }
                    </div>
                    <div className={style.buttons}>
                      <Button texte={questionnaire._id ? "Retour" : "Annuler"} className={style.goBack} onclick={handleGoBack}></Button>          
                      <Button texte={questionnaire._id ? "Sauvegarder" : "Créer le questionnaire"} className={style.saveBtn} onclick={saveQuestionnaire}></Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default QuestionnaireEdit