import {useState} from 'react'
import style from '../login/login.module.css';
import InputForm from '../../components/inputform/InputForm';
import Button from '../../components/button/Button';
import { postData } from '../../components/API/function';
import { CustomResponse } from '../../components/type/Response';
import { Link, useNavigate } from 'react-router-dom';
import { Store } from 'react-notifications-component';

export const Signup = () => {


  const [pseudo,setPseudo] = useState<string>("")
  const [mail,setMail] = useState<string>("")
  const [password,setPassword] = useState<string>("")
  const [password2,setPassword2] = useState<string>("")
  const navigate = useNavigate();


  const TestMailFormat = (email: string): boolean => {
    const mailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return mailRegex.test(email);
  };


  async function register(){
      // if ((password === password2)&& password.length >=8 && TestMailFormat(mail)){
     if ((password === password2)&& password.length >=8){
      const data : Object = {
        login:pseudo,
        password:password,
        password2:password2,
        mail:mail
      }
      await postData<CustomResponse>('/register',data).then((resp)=>{
        if(resp.message ==="Utilisateur enregistré"){
          // Fonction pour changer de page vers "Mainpage"
            navigate('/login');
            Store.addNotification({
              title: "Utilisateur enregistré",
              message: "L'utilisateur a bien été enregistré",
              type: "success",
              insert: "top",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            });
            
        }else{
          console.log(resp.message);
        }
      }).catch((e)=>{
        console.log(e)
      })
    }else 
    if(password !== password2){
      Store.addNotification({
        title: "Mots de passe différents",
        message: "Les mots de passe ne correspondent pas",
        type: "danger",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
    if(password.length < 8){
      Store.addNotification({
        title: "Mot de passe trop court",
        message: "Le mot de passe doit contenir au moins 8 caractères",
        type: "danger",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });    }
    if(!TestMailFormat(mail)){
      Store.addNotification({
        title: "Format de l'email incorrect",
        message: "Le format de l'email est incorrect",
        type: "danger",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });    }
    
  }
 
  return (
    <div className={style.container}>
        <div className={style.header}>
            <div className={style.text}>Inscription</div>
            <div className={style.underline}></div>
        </div>
        <div className={style.inputs}>
            <InputForm placeholder='Pseudo' icon='/img/user_icon.svg' name='pseudo' type='text' alt='utilisateur logo' onChange={setPseudo}></InputForm>
            <InputForm placeholder='Email' icon='/img/email_icon.svg' name='email' type='email' alt='email logo' onChange={setMail}></InputForm>
            <InputForm placeholder='Mot de passe' icon='/img/password_icon.svg' name='password' type='password' alt='cadena logo' onChange={setPassword}></InputForm>
            <InputForm placeholder='Répéter le mot de passe' icon='/img/password_icon.svg' name='password2' type='password' alt='cadena logo' onChange={setPassword2}></InputForm>
        </div>
        
        <Button className={style.submit} onclick={register} texte="S'inscrire"></Button>

        <div className={style.no_account}>
        Déjà inscrit ? <Link to="/login">Se connecter</Link>
        </div>
    </div>
  )
}

export default Signup