import React, { useState } from "react";
import style from './questionBox.module.css';

type propsTypes = {
    question ?: string,
    placeHolder?:string,
    className?:string,
    type?:string
    onchange?: (question:string) => void;
}

function QuestionBox({question,onchange,className,type,placeHolder}:propsTypes) {

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onchange?.(e.target.value);
  };

  return (
    <div className={`${style.container} ${className}`}>
        {type =="edit" ? 
        <input className={style.inputText} 
        type='text' placeholder={placeHolder} value={question} onChange={(e) => handleInputChange(e)}
        ></input>
        :
        <div className={style.text}>{question}</div>
        }
    </div>
  )
}

export default QuestionBox