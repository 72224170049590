import {useState,useEffect,} from 'react'
import DisplayQuestion from '../displayQuestion/DisplayQuestion'
import Classement from '../classement/Classement'
import { socket } from '../../components/API/socket'
import { Question } from '../../components/type/Question'
import { Player } from '../../components/type/Player'
import Waitting from '../waitting/Waitting'
import DisplayResponse from '../displayResponse/DisplayResponse'
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import { Store } from 'react-notifications-component';

function Game() {

    const {gameCode} = useParams();

    const [isStarted,setIsStarted] = useState<boolean>(false)
    const [isFinish,setIsFinish] = useState<boolean>(false)
    const [isResponse,setIsReponse] = useState<boolean>(false)
    const [question,setQuestion] = useState<Question>({_id: "", id_questionnaire:"", question: "Qui est le meilleur développeur de l'équipe ?", reponse: ['Nicolas','Alexis'], reponse_juste: ['Nicolas']})
    const [scores,setScores] = useState<Player[]>([])
    const [player_id,setPlayerId] = useState<string>("")
    const [stats,setStats] = useState<number[]>([0,0,0,0])
    const navigate : NavigateFunction= useNavigate();

    

    useEffect(() =>{

      socket.connect()

      if(gameCode && socket && !isStarted && !isFinish){
        socket.emit("check_room",{gameCode:gameCode})
      }

      socket.on("unknow_room",()=>{
        navigate('/');
        Store.addNotification({
          title: "Partie inexistante",
          message: "Le partie que vous essayez de rejoindre n'existe pas.",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      })

      socket.on("start_game",(data)=>{
          console.log(data);
          setQuestion(data.question)
          setIsStarted(true);
      })

      socket.on("set_question",(data)=>{
        setIsReponse(false)
        setQuestion(data.currentQuestion);
      })

      socket.on("end_game",(data)=>{
        console.log("End game :", data.scores);
        setScores(data.scores);
        setIsFinish(true);
        setTimeout(()=>{
          navigate('/')
        },10000)
      })

      socket.on("set_answer",(data)=>{
        setIsReponse(true)
        setStats(data.stats)
      })

      socket.on("player_id",(data)=>{
        setPlayerId(data.player_id)
        console.log(data.player_id);
      })

      

      return ()=>{
          socket.off("unknow_room");
          socket.off("start_game");
          socket.off('player_id');
          socket.off('end_game');
          socket.off('set_question')
          socket.off('set_answer');
      }
    })

    
  return (
    <div>
        {isFinish ? (
        <Classement tab_classement={scores}/>
        ) : isStarted ? (
          isResponse ? <DisplayResponse question={question} stats={stats}/> : <DisplayQuestion player_id={player_id} question={question} />
      ) : (
        <Waitting/>
      )}
    </div>
  )
}

export default Game