import React, { useState } from 'react';
import style from './questionnaireBox.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { postData } from '../API/function';
import { CustomResponse } from '../type/Response';
import ValidationBox from '../validationBox/ValidationBox';
import { Store } from 'react-notifications-component';



type propsTypes = {
    questionnaireTitre: string,
    nb_question: number,
    className?: string,
    id_questionnaire: string,
    reload?: () => void,
}

function QuestionnaireBox({ questionnaireTitre, nb_question, className, id_questionnaire, reload }: propsTypes) {
    const navigate = useNavigate();

    const EditQuestionnaire = (): void => {
        navigate('/questionnaire', { state: { id_questionnaire: id_questionnaire } });
    }

    const PlayQuestionnaire = () : void => {
        navigate('/launcher',{ state: {questionnaireTitre:questionnaireTitre,questionnaireId:id_questionnaire}});
    }

    const DeleteQuestionnaire = async (): Promise<void> => {
        await postData<CustomResponse>("/removeQuestionnaire", { id_questionnaire: id_questionnaire }).then((resp) => {
            if (resp.message !== 'Questionnaire Suprimmé') {
                console.log(resp.message)
                Store.addNotification({
                    title: "Questionnaire non supprimé",
                    message: "Le questionnaire n'a pas été supprimé",
                    type: "danger",
                    insert: "top",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  });
                
            }else 
            {
                console.log(resp.message)
                Store.addNotification({
                    title: "Questionnaire supprimé",
                    message: "Le questionnaire a bien été supprimé",
                    type: "success",
                    insert: "top",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  });
            }
        })
        reload?.()
    }

    const [isDialogOpen, setDialogOpen] = useState(false);
    const handleOpenDialog = () => {
        setDialogOpen(true);
    };
    const handleCancel = () => {
        // Logique à exécuter lors de l'annulation
        console.log('Action annulée');
        setDialogOpen(false);
    };

    return (

        <div className={style.container}>



            <div className={`${style.questionnaire} ${className}`}>
                <div className={style.titreQuestionnaire}>
                    {questionnaireTitre}
                </div>
                <div className={style.nb_quesionQuestionnaire}>
                    {nb_question === 0
                        ? 'Aucune question'
                        : `${nb_question} question${nb_question === 1 ? '' : 's'}`}
                 </div>
                <div className={style.buttonsQuestionnaire}>
                    <button className={`${style.Btn}`} onClick={EditQuestionnaire}><img src='/img/icon_edit.svg'></img></button>
                    <button className={`${style.Btn} ${style.red}`} onClick={handleOpenDialog}><img src='/img/icon_delete.svg'></img></button>

                    <button className={`${style.Btn} ${style.green}`} onClick={PlayQuestionnaire}><img src='/img/icon_play.svg'></img></button>
                </div>


            </div>
            <ValidationBox isOpen={isDialogOpen} message="Voulez vous vraiment supprimer ce questionnaire ?" onConfirm={DeleteQuestionnaire} onCancel={handleCancel} />

        </div>
    )
}

export default QuestionnaireBox