import React, { useContext, useState } from 'react';
import style from './profilBox.module.css';
import Button from '../button/Button';
import { AuthContext } from "../auth/AuthContext";
import { postData } from '../../components/API/function';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { CustomResponse } from '../../components/type/Response';
import { Store } from 'react-notifications-component';


type propsTypes = {
    className?: string,
    username?: string,
    email?: string,
    img?: string,
    nbrQuestionnaire?: number,
    date?: string,
}

function ProfilBox({ className, username, email, img = "/img/user_icon.svg", nbrQuestionnaire, date }: propsTypes) {
    const navigate: NavigateFunction = useNavigate();
    const { logout } = useContext(AuthContext);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [showPopup, setShowPopup] = useState(false);


    async function changepassworrd(){
     if ((newPassword === confirmNewPassword)&& confirmNewPassword.length >=8){
      const data : Object = {
        mail: email,
        mdp_actuel: oldPassword,
        nouveau_mdp: newPassword,
        nouveau_mdp2: confirmNewPassword,
      }
      await postData<CustomResponse>('/editpassword',data).then((resp)=>{
        if(resp.message ==="Mdp differents"){
          // Fonction pour changer de page vers "Mainpage"
            Store.addNotification({
              title: "Ancien mot de passe erroné",
              message: "Echec de mise a jour du mot de passe",
              type: "danger",
              insert: "top",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            });
          }

        if(resp.message ==="Password Update"){
          // Fonction pour changer de page vers "Mainpage"
            Store.addNotification({
              title: "Mot de passe changé",
              message: "Mot de passe mit à jour",
              type: "success",
              insert: "top",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            });
            
          }else{
            console.log(resp.message);
          }
        }).catch((e)=>{
          console.log(e)
        })
      }else 
          if(newPassword !== confirmNewPassword){
            Store.addNotification({
              title: "Mots de passe différents",
              message: "Les mots de passe ne correspondent pas",
              type: "danger",
              insert: "top",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            });
          }
          if(newPassword.length < 8){
            Store.addNotification({
              title: "Nouveau mot de passe trop court",
              message: "Le mot de passe doit contenir au moins 8 caractères",
              type: "danger",
              insert: "top",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            });    }

        }
  


    const logoutBtn = async () => {
        await logout();
        navigate('/login');
    }

    return (
        <div className={style.Box}>
            <div className={style.header}>
                Profil
                <Button texte='Changer mon mot de passe' onclick={() => setShowPopup(true) } className={style.mdp} ></Button>
                <button onClick={logoutBtn} className={style.logout}><img src='/img/logout.svg'></img> Déconnexion</button>
            </div>
               
               
            <div className={style.underline}></div>
            <div className={style.section}>
                <div className={style.pp}>
                    <img src={img}></img>
                    <p>{username}</p>
                </div>
                <div className={style.infos}>
                    <div className={style.info}>Email : <span>{email}</span></div>
                    <div className={style.info}>Nombre de questionnaires : <span>{nbrQuestionnaire}</span></div>
                    <div className={style.info}>Inscrit depuis le : <span>{date}</span></div>
                </div>
            </div>
            {showPopup && (
                <div className={style.popup}>
              
                        <label >Ancien mot de passe:
                            <input className={style.inputmdp} type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} required />
                        </label>
                        <br />
                        <label>Nouveau mot de passe:
                            <input  className={style.inputmdp} type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                        </label>
                        <br />
                        <label>Repeter nouveau mot de passe:
                            <input  className={style.inputmdp} type="password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} required />
                        </label>
                        <br />  
                        <div className={style.popupButtons}>
                        <Button className={style.mdp} onclick={changepassworrd} texte="Changer"></Button>
                    <Button className={style.fermer} texte='Fermer' onclick={() => setShowPopup(false)}></Button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ProfilBox;
