import React from 'react'
import style from './responseBox.module.css'

type propsTypes = {
    response? : string,
    className? : string,
    onClick? : (response:string) => void
}

function ResponseBox({response,className,onClick}:propsTypes) {


  
  return (
    <div onClick={()=>{response ? onClick?.(response) : ()=>{}}} className={response ? `${style.container} ${className}` :`${style.emptyContainer} ${className}` }>
        <p className={style.text}>{response}</p>
    </div>
  )
}

export default ResponseBox