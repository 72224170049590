import React,{useContext, useState} from 'react'
import style from './displayQuestion.module.css'
import QuestionBox from '../../components/questionBox/QuestionBox'
import ResponseBox from '../../components/responseBox/ResponseBox'
import Timer from '../../components/timer/Timer'
import { Question } from '../../components/type/Question'
import { useParams } from 'react-router-dom'
import { socket } from '../../components/API/socket'

type propsTypes = {
  question:Question,
  player_id?:string,
}

function DisplayQuestion({question,player_id }:propsTypes) {

  const [responseAnswered,setResponseAnswered] = useState<string>()
  const [remainingTime, setRemainingTime] = useState<number>(15)
  const {gameCode} = useParams();
  //const response: string[] = ['Nicolas', 'Alexis', 'Maël', 'Maxime']
    const nombre_secondes:number = 15;
    
    const handleTimerEnd = () => {
        console.log('Le timer a atteint zéro !'); // Remplacez ceci par l'action que vous souhaitez exécuter
    };

  if(question === undefined){
    question = {_id: "", id_questionnaire:"", question: "Qui est le meilleur développeur de l'équipe ?", reponse: ['Nicolas','Alexis','Maël','Maxime'], reponse_juste: ['Nicolas']}
  }


  const selectAnswer = (response:string):void =>{
    if (player_id && gameCode && !responseAnswered){
      setResponseAnswered(response)
      socket.emit("answer",{gameCode:gameCode,response:response,player_id:player_id,temps_reponse:nombre_secondes-remainingTime})
    }
  }

    return (
        <div className={style.background}>
            <div className={style.container}>
                <div className={style.question_timer}>
                    <QuestionBox className={style.questionBox} question={question.question}></QuestionBox>
                    <Timer nombre_secondes={nombre_secondes} onTimerTick={(seconds)=>setRemainingTime(seconds)}onTimerEnd={handleTimerEnd} />
                </div>
                <div className={style.imageContainer}>
                    <img className={style.picture} src='/img/logo.svg'></img>
                </div>


                <div className={style.responseContainer}>
                    {/*<div className={style.responseLine}>
                        <ResponseBox className={style.responseBox} response={response[0]}></ResponseBox>
                        <ResponseBox className={style.responseBox} response={response[1]}></ResponseBox>
                    </div>
                    <div className={style.responseLine}>
                        <ResponseBox className={style.responseBox} response={response[2]}></ResponseBox>
                        <ResponseBox className={style.responseBox} response={response[3]}></ResponseBox>
                    </div>*/}
                 {question.reponse.map((responseText, index) => (
                    index % 2 === 0 && (
                    <div key={index / 2} className={style.responseLine}>
                        <ResponseBox onClick={selectAnswer} className={responseAnswered ? (responseAnswered===responseText? style.responseBoxAnswered : style.responseBoxNotAnswered) :style.responseBox} response={responseText}></ResponseBox>
                        {question.reponse[index + 1] ? 
                        <ResponseBox onClick={selectAnswer} className={responseAnswered ? (responseAnswered===question.reponse[index + 1]? style.responseBoxAnswered : style.responseBoxNotAnswered) :style.responseBox} response={question.reponse[index + 1]}></ResponseBox>
                        : <ResponseBox  className={style.responseBox}></ResponseBox>}

                    </div>
                        )))}
                  
            </div>
            </div>
        </div>
    )
}

export default DisplayQuestion