import { ReactNode,createContext,useState,useEffect } from "react"
import { User } from "../type/User"
import { postData } from "../API/function"
import { CustomResponse } from "../type/Response"

type Props = {
    children?: ReactNode
}


type userContext = User|undefined

type AuthContext = {
    user:userContext,
    setUser: (newState:userContext)=>void,
    logout: ()=>Promise<void> |void ,

}

const initValue = {
    user:undefined,
    setUser : (newState:userContext)=>{},
    logout: ()=>{}
}




const AuthContext = createContext<AuthContext>(initValue)


const AuthProvider = ({children} : Props)=>{

    async function logout() {
      
      await postData<CustomResponse>('/logout').then((resp)=>{
        console.log((resp as CustomResponse).message)
        setUser(undefined);
      }).catch((e)=>{
        console.log(e)
      })

    };

    async function logUser(){

      await postData<User | CustomResponse>('/login').then((resp)=>{
        if (!(resp as CustomResponse).message){
          const respUser = resp as User
          setUser({mail:respUser?.mail,login:respUser?.login,_id:respUser?._id})    
        }
        else{
          console.log((resp as CustomResponse).message)
        }
        
      }).catch((e)=>{
        console.log(e)
      })
    }

    
    useEffect(() => {
        logUser();
      }, []);

    const [user,setUser] = useState<userContext>(initValue.user)

    return (
        <AuthContext.Provider value={{user, setUser,logout}}>
            {children}
        </AuthContext.Provider>
    )
}

export {AuthContext, AuthProvider}