import style from './displayResonse.module.css';
import ResponseBox from '../../components/responseBox/ResponseBox';
import QuestionBox from '../../components/questionBox/QuestionBox';
import BarGraph from '../../components/barGraph/BarGraph';
import { Question } from '../../components/type/Question';

type propsType = {
    question : Question,
    stats: number[]
}

function DisplayResponse({question,stats}:propsType) {

    const response : string[] = ['Nicolas','Alexis','Maël','Maxime']
    const correct : boolean[] = [true,true,false,true]

  return (
    <div className={style.background}>
        <div className={style.container}>
            <QuestionBox className={style.questionBox} question={question.question}></QuestionBox>
            <div className={style.imageContainer}>
                <BarGraph stats={stats}></BarGraph>
            </div>
            <div className={style.responseContainer}>
                {/*<div className={style.responseLine}>
                    <ResponseBox className={correct[0] ? style.trueResponseBox : style.falseResponseBox} response={response[0]}></ResponseBox>
                    <ResponseBox className={correct[1] ? style.trueResponseBox : style.falseResponseBox} response={response[1]}></ResponseBox>
                </div>
                <div className={style.responseLine}>
                    <ResponseBox className={correct[2] ? style.trueResponseBox : style.falseResponseBox} response={response[2]}></ResponseBox>
                    <ResponseBox className={correct[3] ? style.trueResponseBox : style.falseResponseBox} response={response[3]}></ResponseBox>
  </div>*/}
                {question.reponse.map((responseText, index) => (
                    index % 2 === 0 && (
                    <div key={index / 2} className={style.responseLine}>
                        <ResponseBox  className={question.reponse_juste.some((elem)=>responseText===elem) ? style.trueResponseBox : style.falseResponseBox} response={responseText}></ResponseBox>
                        {question.reponse[index + 1] ?
                        <ResponseBox  className={question.reponse_juste.some((elem)=>question.reponse[index + 1]===elem) ? style.trueResponseBox : style.falseResponseBox} response={question.reponse[index + 1]}></ResponseBox>
                        : <ResponseBox  className={style.responseBox}></ResponseBox>}
                    </div>
                    )))}
            </div>
        </div>
    </div>
  )
}

export default DisplayResponse