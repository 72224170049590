import { Sign } from 'crypto';
import React from 'react';
import logo from './logo.svg';
import Home from './pages/home/Home';
import Launcher from './pages/launcher/Launcher';
import Login from './pages/login/Login';
import Signup from './pages/signup/Signup';
import DisplayQuestion from './pages/displayQuestion/DisplayQuestion';
import MainPage from './pages/mainPage/MainPage';
import DisplayResponse from './pages/displayResponse/DisplayResponse';
import Navpage from './pages/navpage/navpage';
import QuestionnaireEdit from './pages/questionnaireEdit/QuestionnaireEdit';

function App() {
  return (
    <div className="App">
    
      <Navpage/>
      {/*<Signup />
      <Login />
      <Home />
      <Classement/>
      <MainPage />*/}
    </div>
  );
} 

export default App;
